import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { last, isEmpty, max, mergeWith, pickBy, pull, sumBy } from 'lodash'
import { Button, Divider, message, Row, Popconfirm, Spin } from 'antd'
import { BinBadge, SortingItem, SortingQuantityModal } from '../../components'

import noImgPackage from '../../../_shared/assets/images/no-image-pack.jpeg'

import { getBin, setBinSortingStatusToClose } from '../../api'

import { currentSortingDetails } from '../../Layout'

const SortingPage = ({ barcodeList, ids }) => {
  let binResultBody = {}

  const [isSpinLoading, setIsSpinLoading] = useState(false)
  const [percent, setPercent] = useState(0)
  const [searchParams, setSearchParams] = useSearchParams('')
  const [isLoading, setIsLoading] = useState(true)
  const [selectedPackage, setSelectedPackage] = useState(null)
  const [binStatus, setBinStatus] = useState(ids.map(id => ({ code: null, id, isLoading: true, packageNumber: 0 })))
  const [packages, setPackages] = useState([])

  const currentSearchParams = searchParams.getAll('sorting_ids') || []

  const spin = {
    isLoading: isSpinLoading,
    setIsLoading: setIsSpinLoading,
    setPercent,
  }

  useEffect(() => {
    if (selectedPackage || currentSortingDetails.value.modalVisiblity.notListedItem.value || isEmpty(currentSortingDetails.value.barCodeAction.value)) return

    if (!packages[currentSortingDetails.value.barCodeAction.value]) {
      currentSortingDetails.value.modalVisiblity.notListedItem.value = true
    }

  //eslint-disable-next-line
  }, [currentSortingDetails.value.barCodeAction.value])

  useEffect(() => {
    if (currentSortingDetails.value.closingInProgress.value) return

    Promise.all(
      ids.map((id, index) =>
        getBin(id)
          .then(data => {
            currentSortingDetails.value.fetchedBins.value[id] = data

            setBinStatus(oldArray => {
              let updatedArray = oldArray
              updatedArray[index] = { code: data.identity_code, id, isLoading: false, packageNumber: sumBy(data.sorting_event.packages, 'quantity')}

              return updatedArray
            })
          })
          .catch(error => {
            currentSortingDetails.value.fetchedBins.value[id] = null
            setBinStatus(oldArray => {
              let updatedArray = oldArray
              updatedArray[index] = { code: null, id, isLoading: false, packageNumber: 0 }

              return updatedArray
            })
          })
      ))
      .finally(() => {
        const mergedPackages = {}
        Object.keys(currentSortingDetails.value.fetchedBins.value).forEach((binId) => {

          const bin = currentSortingDetails.value.fetchedBins.value[binId]

          bin.sorting_event.packages.forEach((pkg) => {
            mergedPackages[pkg.upc] = {
              ...pkg,
              bin_id: binId,
              quantity: pkg.quantity + (mergedPackages[pkg.upc] ? mergedPackages[pkg.upc].quantity : 0),
            }
          })
        })

        setPackages(mergedPackages)
        setIsLoading(false)
      })
  }, [ids])

  const resetApp = () => {
    currentSortingDetails.value.selectedBins.value = []
    currentSortingDetails.value.sortedQuantities.value = {}
    currentSortingDetails.value.fetchedBins.value = {}
    currentSortingDetails.value.noMoreItem.value = []
    currentSortingDetails.value.search.value = ''

    localStorage.removeItem('no_more_barcode')
    localStorage.removeItem('sorted_barcode_qty')

    setTimeout(() => {
      currentSortingDetails.value.closingInProgress.value = false
      spin.setIsLoading(false)
    }, 500)
  }

  const handleCloseBatch = () => {
    spin.setIsLoading(true)

    let remainingItems = Object.assign({}, currentSortingDetails.value.sortedQuantities.value)
    const bins = currentSortingDetails.value.fetchedBins.value
    const calculatedPercentageStep = 110 / bins.length

    binResultBody = {}

    Object.keys(bins).forEach(binId => {
      const binPackages = bins[binId].sorting_event.packages

      binResultBody[binId] = binResultBody[binId] || {}

      binPackages.forEach(pckg => {
        const hasScannedItem = (remainingItems[pckg.upc] && remainingItems[pckg.upc] > 0)
        const scanned_qty = (pckg.quantity < remainingItems[pckg.upc]) ? pckg.quantity : max([remainingItems[pckg.upc], 0])

        binResultBody[binId][pckg.upc] = {
          not_found_qty: pckg.quantity - scanned_qty,
          scanned_qty,
        }

        if (hasScannedItem)
          remainingItems[pckg.upc] = remainingItems[pckg.upc] - pckg.quantity
      })
    })

    const notListedItems = pickBy(remainingItems , (itemQty) => itemQty > 0)

    binResultBody[last(Object.keys(binResultBody))] = mergeWith(
      binResultBody[last(Object.keys(binResultBody))],
      notListedItems,
      (objValue = 0, srcValue = 0) => objValue + srcValue
    )
    currentSortingDetails.value.closingInProgress.value = true
    setBinsStatusForClose(0, calculatedPercentageStep)
  }

  const setBinsStatusForClose = (index, calculatedPercentageStep) => {
    const binId = Object.keys(currentSortingDetails.value.fetchedBins.value)[index]

    const result = {
      ...binResultBody[binId],
    }

    setBinSortingStatusToClose({ result, sorting_bin_id: binId })
      .then(() => {
        const newParams = pull(currentSearchParams, binId)
        setSearchParams({ sorting_ids: newParams })
      })
      .catch((error) => {
        console.warn(`Error id: #${binId}`)
        console.warn(error)
      })
      .finally(() => {
        const nextIndex = index + 1

        spin.setPercent((nextIndex) * calculatedPercentageStep)

        if (nextIndex < Object.keys(binResultBody).length) {
          setBinsStatusForClose(nextIndex, calculatedPercentageStep)
        } else {
          resetApp()
          message.success({
            content: <Trans>sorting_tools:done</Trans>,
          })
        }
      })
  }

  if (isLoading) return <Spin fullscreen name="loadingSpinnerSelenium" />

  const searchValue = currentSortingDetails.value.search.value.toLowerCase()

  const filteredPackages = Object.keys(packages).filter(barcode => {
    const remainingItem = packages[barcode].quantity - (currentSortingDetails.value.sortedQuantities.value[barcode] || 0)

    return !((remainingItem <= 0) || currentSortingDetails.value.noMoreItem.value.includes(barcode) || !barcodeList[barcode])
  })

  return (
    <>
      <Row>
        <BinBadge bins={binStatus} />
      </Row>

      <Divider style={{ borderColor: '#7cb305'}} variant="dotted">
        <Trans>sorting_tools:packages</Trans>
      </Divider>

      <Row gutter={[16, 16]} justify="left" style={{ marginTop: 30 }} type="flex">
        {filteredPackages.length === 0 && (
          <Popconfirm
            cancelText={<Trans>sorting_tools:cancel</Trans>}
            description={<Trans>sorting_tools:areYouSureClose</Trans>}
            okText={<Trans>sorting_tools:ok</Trans>}
            onConfirm={handleCloseBatch}
            title={<Trans>sorting_tools:closeBatchPopup</Trans>}
          >
            <Button
              className="close-batch-btn"
              size="large"
              style={{ flex: 1 }}
            >
              <Trans>sorting_tools:closeBatch</Trans>
            </Button>
          </Popconfirm>
        )}

        {React.Children.toArray(filteredPackages.map((barcode, index) => {
          const product = barcodeList[barcode]

          if (!product) {
            console.warn(`${barcode} missing`)
            return null
          }

          return (
            <SortingItem
              barcode={barcode}
              image={product.media[0]?.small_url || noImgPackage}
              index={index}
              isHighlighted={product.product_name.toLowerCase().includes(searchValue) || barcode.toLowerCase().includes(searchValue) || product.sorting_key.toLowerCase().includes(searchValue)}
              name={product.product_name || ''}
              quantity={packages[barcode].quantity}
              selectPackage={setSelectedPackage}
            />
          )
        }))}
      </Row>

      {!isEmpty(selectedPackage) &&
        <SortingQuantityModal
          onClose={() => setSelectedPackage(null)}
          selectedPackage={selectedPackage}
        />
      }

      <Spin fullscreen percent={percent} spinning={isLoading} />
    </>
  )}

SortingPage.propTypes = {
  barcodeList: PropTypes.object,
  ids: PropTypes.array,
}

export default SortingPage
