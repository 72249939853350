import { getHeaders, apiResponseHandler } from '../../../_shared/api-utils'

export const CONFIG_ENDPOINT = '/api/admin/sorting_bins'

export const setBinsToSortingBulk = ({ sorting_bin_id }) => fetch(
  `${CONFIG_ENDPOINT}/bulk_sorting`,
  {
    body: JSON.stringify({
      sorting_bin_id,
    }),
    headers: getHeaders(),
    method: 'PUT',
  },
).then(apiResponseHandler)
