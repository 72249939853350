import React, { useEffect, useState } from 'react'
import { Badge, Button, Modal } from 'antd'
import { signal } from '@preact/signals-react'
import { useTranslation } from 'react-i18next'
import { sum } from 'lodash'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { getAllShippingContainer } from './api'

dayjs.extend(utc)

const currentDate = dayjs.utc(new Date()).format('YYYY-MM-DD')

const dailyCounter = signal({})

export const updateDailyCounters = () => {
  let currentArray = {}

  getAllShippingContainer(`?data_processing[]=processed_data&from=${currentDate}-00:00:00&to=${currentDate}}-23:59:59&includes[with_packages]=true`)
    .then(({ shipping_containers }) => {
      shipping_containers.forEach(({ packages }) => {
        packages.forEach(({ upc, quantity }) => {
          currentArray[upc] = (currentArray[upc] || 0 ) + quantity
        })
      })

      dailyCounter.value = currentArray
    })
    .catch((error) => {
      console.log(error.message)
    })
}

const DailyCounter = () => {
  const { t } = useTranslation()

  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    updateDailyCounters()
  }, [])

  return (
    <>
      <Badge.Ribbon
        text={
          <Button href="#" onClick={() => setIsModalOpen(true)} type="link">
            <span style={{ color: 'white' }}>{t('checkin:itemCount')}:</span> <Badge color="blue" count={sum(Object.values(dailyCounter.value))} overflowCount={9999} showZero style={{ boxShadow: 'none' }} />
          </Button>
        }
      />

      <Modal
        footer={null}
        onCancel={() => setIsModalOpen(false)}
        open={isModalOpen}
        title={t('checkin:itemCount')}
      >
        {Object.keys(dailyCounter.value).map((key) => (<p key={key}><b>{key}:</b> {dailyCounter.value[key]} </p>))}
      </Modal>
    </>
  )
}

export default DailyCounter
