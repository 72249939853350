/*eslint no-template-curly-in-string: "off"*/
/*eslint sort-keys: "off"*/

const en = {
  addBarcode: 'Add barcode',
  addToList: 'Add to list',
  alreadyUsed: 'This packaging is already in our system, it must be returned to the distributor.',
  breakInfoText: 'Scan session paused',
  cancel: 'Cancel',
  conditionIncomplete: 'Incomplete',
  conditionMoreInfoDamaged: 'Damaged (fractured/broken container)',
  conditionMoreInfoIncomplete: 'Incomplete (missing covers, components and parts)',
  conditionMoreInfoUnprocessable: 'Unprocessable (difficult to determine the condition of the item, brand, category, Loop or not)',
  conditionOK: 'OK',
  conditionShippingContainerDamaged: 'Shipping container damaged',
  conditionUnprocessable: 'Unprocessable',
  delete: 'Delete',
  done: 'Great, we have done all the operations!',
  edit: 'Edit',
  finish: 'Finish',
  itemCount: 'Item count',
  itemCountTable: 'Item count',
  loadedFromReturn: 'Shipping container loaded from open return',
  loadingPartners: 'Loading partners...',
  localeName: ' English',
  newReturn: 'Start a new return?',
  next: 'Next',
  no: 'No',
  noStoreId: 'Unable to complete scan session: Store ID missing. Please contact your Loop representative.',
  oneTimeConfirmCancel: 'Cancel',
  oneTimeConfirmOk: 'Add anyway',
  oneTimeConfirmQuestion: 'There should be only 1 item added to this kind of QR code. Are you sure the quantity is right?',
  packages: 'Packages',
  packageSaved: 'Package saved',
  scanQrCode: 'Scan in the QR code',
  selectACountry: 'Select a country',
  selectAPartner: 'Select a partner',
  selectedPartner: 'Selected Partner',
  sendBack: 'Send back',
  shippingContainerSuccessfullySaved: 'Shipping container successfully saved',
  statusChanged: 'Shipping container status has been changed to "sent back" successfully.',
  step1Error: 'You do not have partner(s)',
  step1Success: 'Country ({{country}}) and Partner code ({{partner}}) set successfully',
  step1Title: 'Select Partner',
  step2ErrorQrCode: 'QR code is invalid',
  step2ErrorQrCodeInCountry: 'QR code is not valid in the selected country',
  step2Help1: 'The QR code is a sticker on the package or a label on the bag/tote',
  step2Help2: 'If there is NO QR code on the package',
  step2Help2_1: 'Use the "Orphan QR code list"',
  step2Help2_2: 'Scan in one and cross it out from the list.',
  step2MoreSTores: 'We found some stores with this ID, please select the correct one',
  step2QrCodeValid: 'QR code is valid, and saved.',
  step2Title: 'Scan Store Id',
  step3AlreadyExistsError: 'Shipping container with identity_code {{code}} already exists',
  step3Comment: 'Comment',
  step3Condition: 'Condition',
  step3ErrorStore: 'Store id does not exist',
  step3ScanStoreId: 'Scan in the store id',
  step3Success: 'Fields saved.',
  step3Title: 'Scan QR Code',
  step4BarcodeStatusDamaged: 'Damaged',
  step4BarcodeStatusDamagedIncomplete: 'Damaged - Incomplete',
  step4BarcodeStatusDamagedIncompleteMoreInfo: 'Damaged-Incomplete (missing covers, components and parts and fractured/broken container)',
  step4BarcodeStatusDamagedMoreInfo: 'Damaged (fractured/broken container)',
  step4BarcodeStatusIncomplete: 'Incomplete',
  step4BarcodeStatusIncompleteMoreInfo: 'Please add more info here',
  step4BarcodeStatusOK: 'OK',
  step4Comment: 'Comment',
  step4Condition: 'Condition',
  step4Delete: 'Are you sure you want to delete all {{qty}} package(s)?',
  step4ErrorBarcode: 'Barcode does not exist',
  step4MissingInfo: 'Missing label info',
  step4MissingInfoDesc: 'Label is missing: More info here please.',
  step4MissingLabel: 'Missing label?',
  step4ProductBarcode: 'Product barcode',
  step4ProductName: 'Product name',
  step4Quantity: 'Quantity',
  step4ScanBarcode: 'Scan in the Barcode',
  step4SKU: 'SKU',
  step4Title: 'Scan Barcode',
  updatePackage: 'Update package',
  yes: 'Yes',
  openReturns: 'Open returns',
  qrCode: 'QR code',
  startedAt: 'Started At',
  actions: 'Actions',
  continue: 'Continue',
  break: 'Break',
  autoPackageComment: 'Automatically added multi-usage-package',
  manualInputWarning: 'Manual Barcode Input: please verify it is a Loop SKU before validation.',
  sortingInputLabel: 'Sorting ID',
  sortingSaveLabel: 'Set Sorting ID',
  sortingCloseLabel: 'Close this Sorting ID',
  sortingCloseConfirmTitle: 'Closing {{sortingID}}',
  sortingCloseConfirmDesc: 'Are you sure?',
  sortingIdTooltip: 'First, you must close the active Sorting ID',
  sortingIdEmptyErrorTitle: 'You forgot something.',
  sortingIdEmptyError: 'Sorting ID is empty, please fill it in.',
  sortingIdCountryError: 'Before changing the country, you must first close the open Sorting ID.',
  sortingIdError: 'Changing the country?',
  sortingIdClosed: 'Sorting bin has been closed',
  step4NonLoopProduct: 'Non-Loop return',
  newReturnandCloseSortingId: 'Start a new return \n  and close current sorting id',
  or: 'or',
}

const fr = {
  addBarcode: 'Ajouter le code-barres',
  addToList: 'Ajouter à la liste (ajouter)',
  alreadyUsed: 'Cet emballage est déjà dans notre système, il est doit être renvoyé au distributeur',
  breakInfoText: 'Session de scan mise en pause',
  cancel: 'Annuler',
  conditionIncomplete: 'Incomplet',
  conditionMoreInfoDamaged: 'Endommagé (contenant fracturé/cassé)',
  conditionMoreInfoIncomplete: 'Incomplet (couvercles, composants et pièces manquants) ',
  conditionMoreInfoUnprocessable: 'Non-traitable (difficile de déterminer l\'état de l\'article, la marque, la catégorie, Loop ou non) ',
  conditionOK: 'OK',
  conditionShippingContainerDamaged: 'Contenant endommagé',
  conditionUnprocessable: 'Non-traitable',
  delete: 'Supprimer',
  done: 'Super, opérations terminées',
  edit: 'Modifier',
  finish: 'Terminer ',
  itemCount: 'Scans du jour',
  itemCountTable: 'Nombre d’Articles',
  loadedFromReturn: 'Reprise de la session de scan ouverte',
  loadingPartners: 'Chargement en cours...',
  localeName: ' Français',
  newReturn: 'Démarrer un nouveau scan',
  next: 'Etape Suivante',
  no: 'Non',
  noStoreId: 'Impossible de finaliser la session de scan: Identifiant magasin manquant. Merci de contacter votre interlocuteur Loop.',
  oneTimeConfirmCancel: 'Annuler',
  oneTimeConfirmOk: 'Ajouter quand même',
  oneTimeConfirmQuestion: 'Il ne devrait y avoir qu\'un seul article ajouté à ce type de QR-code. Êtes-vous sûr que la quantité est correcte ?',
  packages: 'Emballage',
  packageSaved: 'Code-barres enregistré',
  scanQrCode: 'Scannez le code QR',
  selectACountry: 'Sélectionnez un pays',
  selectAPartner: 'Sélectionnez un partenaire',
  selectedPartner: 'Partenaire sélectionné',
  sendBack: 'Renvoi au distributeur',
  shippingContainerSuccessfullySaved: 'Retour enregistré',
  statusChanged: 'Succès: le statut a bien été modifié à "Renvoyé"',
  step1Error: 'Vous n\'avez pas de partenaire (En cas d\'absence de partenaire)',
  step1Success: 'Pays ({{country}}) et Code Partenaire ({{partner}}) définis avec succès ',
  step1Title: 'Choisir un partenaire',
  step2ErrorQrCode: 'QR Code Invalide',
  step2ErrorQrCodeInCountry: 'Le QR code n\'est pas valide dans le pays sélectionné ',
  step2Help1: 'Le QR Code est un autocollant apposé sur le contenant ou une étiquette sur l\'emballage (sac ou tote).',
  step2Help2: 'S\'il n\'y a PAS de QR Code sur le contenant',
  step2Help2_1: 'Utilisez un code de la "liste des QR codes orphelins".',
  step2Help2_2: 'Scannez un code et rayez-le de la liste.',
  step2MoreSTores: 'We found some stores with this ID, please select the correct one',
  step2QrCodeValid: 'Le QR code est valide et enregistré.',
  step2Title: 'Scannez l\'identifiant magasin',
  step3AlreadyExistsError: 'L’emballage avec l’identifiant {{code}} existe déjà.',
  step3Comment: 'Commentaires',
  step3Condition: 'État',
  step3ErrorStore: 'L\'identifiant magasin n\'existe pas',
  step3ScanStoreId: 'Scannez l\'identifiant magasin',
  step3Success: 'Champs enregistrés',
  step3Title: 'Scan du QR Code',
  step4BarcodeStatusDamaged: 'Endommagé',
  step4BarcodeStatusDamagedIncomplete: 'Endommagé - Incomplet',
  step4BarcodeStatusDamagedIncompleteMoreInfo: 'Endommagé - Incomplet (contenant fracturé/cassé manquants; couvercles, composants et pièces)',
  step4BarcodeStatusDamagedMoreInfo: 'Endommagé (contenant fracturé/cassé)',
  step4BarcodeStatusIncomplete: 'Incomplet',
  step4BarcodeStatusIncompleteMoreInfo: 'Incomplet (couvercles, composants et pièces manquants) ',
  step4BarcodeStatusOK: 'OK',
  step4Comment: 'Commentaire',
  step4Condition: 'État',
  step4Delete: 'Êtes-vous sûr de vouloir supprimer le(s) contenant(s)? ',
  step4ErrorBarcode: 'Le code-barres n\'existe pas ',
  step4MissingInfo: 'Infos étiquette manquante',
  step4MissingInfoDesc: 'L\'étiquette est manquante, plus d\'informations ici ',
  step4MissingLabel: 'Étiquette manquante ?',
  step4ProductBarcode: 'Code-barres produit',
  step4ProductName: 'Nom du produit ',
  step4Quantity: 'Quantité ',
  step4ScanBarcode: 'Scanner le code-barres',
  step4SKU: 'Référence',
  step4Title: 'Scan du code-barres',
  updatePackage: 'Mise à jour de l\'article',
  yes: 'Oui',
  openReturns: 'Session de scan ouverte',
  qrCode: 'QR-Code',
  startedAt: 'Début',
  actions: 'Actions',
  continue: 'Continuer',
  break: 'Pause',
  autoPackageComment: 'Emballage à usage multiple ajouté aumatiquement',
  manualInputWarning: 'Saisie manuelle code-barres: merci de vérifier qu\'il s\'agit d\'un produit Loop avant validation.',
  sortingInputLabel: 'Identifiant de tri',
  sortingSaveLabel: 'Définir l\'ID de tri',
  sortingCloseLabel: 'Clôture de l\'ID de tri',
  sortingCloseConfirmTitle: 'Clôture {{sortingID}}',
  sortingCloseConfirmDesc: 'Etes-vous sûr ?',
  sortingIdTooltip: 'Vous devez d\'abord clôturer l\'identifiant de tri en cours',
  sortingIdEmptyErrorTitle: 'Vous avez oublié quelque chose',
  sortingIdEmptyError: 'Le champs de l\'identifiant de tri est vide, merci de le renseigner',
  sortingIdCountryError: 'Avant de changer de pays, le lot de tri doit être clôturé',
  sortingIdError: 'Changer de pays ?',
  sortingIdClosed: 'Le lot de tri a été clôturé',
  step4NonLoopProduct: 'Retours Non-Loop',
  newReturnandCloseSortingId: 'Clôturer le lot de tri et \n démarrer un nouveau scan',
  or: 'ou',
}

const translations = {
  en,
  fr,
}

export default translations
