/*eslint no-template-curly-in-string: "off"*/
/*eslint sort-keys: "off"*/

const en = {
  allSorted: 'All sorted',
  closeBatch: 'Close Batch',
  sortingSelectedBins: 'Sorting selected bins ({{count}})',
  enterQuantity: 'Enter quantity',
  packages: 'Packages',
  noListedItemFound: 'Not listed item found',
  quantity: 'Quantity',
  barcode: 'Barcode',
  done: 'Done',
  areYouSureClose: 'Are you sure you want to close the batch?',
  closeBatchPopup: 'Close Batch Confrim',
  notListedItemAdded: 'Not listed item added',
  youHaveSortingInProgress: 'You have sorting in progress',
  barcodeRequired: 'Barcode required',
  quantityRequired: 'Quantity required',
  cancel: 'Cancel',
  cancelation: 'Cancel',
  ok: 'Ok',
  youWorkingOnThis: 'Currently loaded bins:',
  searchInput: 'Search',
  package_one: '{{count}} package',
  package_other: '{{count}} packages',
  noMoreItemButton: 'No more Item?',
  areYouSureNoMore: 'Are you sure no more?',
  yes: 'Yes',
  noMoreItem: 'No more item',
}

const fr = {
  allSorted: 'Tri terminé',
  closeBatch: 'Clôture du lot',
  sortingSelectedBins: 'Bacs de tri sélectionnés ({{count}})',
  enterQuantity: 'Modifier la quantité',
  packages: 'Emballages',
  noListedItemFound: 'Unité(s) additionnelle(s) trouvée(s)',
  quantity: 'Quantité',
  barcode: 'Code-barres',
  done: 'Terminé',
  areYouSureClose: 'Etes-vous sûr de vouloir clôturer le lot ?',
  closeBatchPopup: 'Clôture du lot confirmée',
  notListedItemAdded: 'Unité(s) additionnelle(s) ajoutée(s)',
  youHaveSortingInProgress: 'Tri en cours',
  barcodeRequired: 'Code-barres requis',
  quantityRequired: 'Quantité requise',
  cancel: 'Annuler',
  cancelation: 'Annulation',
  ok: 'OK',
  youWorkingOnThis: 'Bacs en cours de tri',
  searchInput: 'Recherche',
  package_one: '{{count}} emballage',
  package_other: '{{count}} emballages',
  noMoreItemButton: 'Pas d\'autre unité trouvée',
  areYouSureNoMore: 'Etes-vous sûr qu\'il manque des unités ?',
  yes: 'Oui',
  noMoreItem: 'Pas d\'autre unité trouvée',
}

const translations = {
  en,
  fr,
}

export default translations
