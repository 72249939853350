import React from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Form, Input } from 'antd'
import { NotListedItemModal } from '../../'

import { currentSortingDetails } from '../../../Layout'

const { Search } = Input

export const SortingTable = ({ spin }) => {
  //eslint-disable-next-line
  const { t } = useTranslation()

  const onSearch = (value, event) => {
    event.preventDefault()

    currentSortingDetails.value.search.value = (value.length < 3) ? '' : value
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Form
        className="search-form"
        style={{
          display: 'flex',
          flex: 1,
          gap: '10px',
        }}
      >
        <Form.Item style={{ flex: 1 }}>
          <Search
            allowClear
            onSearch={onSearch}
            placeholder={t('sorting_tools:searchInput')}
            size="large"
          />
        </Form.Item>
      </Form>

      <Button onClick={() => currentSortingDetails.value.modalVisiblity.notListedItem.value = true} size="large" style={{ flex: 1, margin: '0 10px' }} type="primary">
        <Trans>sorting_tools:noListedItemFound</Trans>
      </Button>

      {currentSortingDetails.value.modalVisiblity.notListedItem.value &&
        <NotListedItemModal onCancel={() => currentSortingDetails.value.modalVisiblity.notListedItem.value = false} />
      }
    </div>
  )
}

SortingTable.propTypes = {
  spin: PropTypes.object,
}

export default SortingTable
