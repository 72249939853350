import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { SmileOutlined } from '@ant-design/icons'
import { Col, Divider, message, Result } from 'antd'

import { NavigateButton, updateDailyCounters, updateDraftCounters } from '../../../components/'

import { currentScanDetails } from '../../../Layout'

export const resetForm = () => {
  message.destroy()

  currentScanDetails.value.stepIsLoading.value = false

  currentScanDetails.value.step.value = 0
  currentScanDetails.value.data.value = [
    {
      code: null,
      country: null,
    },
    {
      store_id: null,
    },
    {
      comment: null,
      condition: 'ok',
      identity_code: null,
    },
  ]
}

const Finish = () => {
  const { t } = useTranslation()

  useEffect(() => {
    updateDailyCounters()
    updateDraftCounters()

    setTimeout(() => {
      message.destroy()
    }, 2000)
  }, [])

  if (currentScanDetails.value.barCodeAction.value === '::loop::finishForm') {
    setTimeout(() => {
      resetForm()
    }, 200)

    currentScanDetails.value.barCodeAction.value = null
  }

  const resetFormAndSortingID = () => {
    currentScanDetails.value.barCodeAction.value = '::loop::finishAndCloseForm'
  }

  return (
    <Result
      extra={
        <Col className="finish-button-group">
          <NavigateButton
            label={t('checkin:newReturn')}
            loading={currentScanDetails.value.stepIsLoading.value}
            onClick={resetForm}
            qrAction="::loop::finishForm"
          />
          <Divider plain>{t('checkin:or')}</Divider>
          <NavigateButton
            label={t('checkin:newReturnandCloseSortingId')}
            loading={currentScanDetails.value.stepIsLoading.value}
            onClick={resetFormAndSortingID}
            qrAction="::loop::finishAndCloseForm"
          />
        </Col>
      }
      icon={<SmileOutlined />}
      title={t('checkin:done')}
    />
  )
}

export default Finish
